import React, { useEffect, useState } from "react";
import bannerLarge from "../../assets/images/promobanner/promo_feb_25.jpg";
import bannerLargeAr from "../../assets/images/promobanner/promo_feb_25_AR.jpg";
import bannerMobile from "../../assets/images/promobanner/promo_mobile_feb_25.jpg";
import bannerMobileAr from "../../assets/images/promobanner/promo_mobile_feb_25_AR.jpg";
import offerTag from "../../assets/images/offer_tag.svg";
import offerTagFlipped from "../../assets/images/offer_tag_flipped.svg";
import { API } from "../../http/API";
import { useSelector } from "react-redux";
import {
  limitedTimeOffer,
  limitedTimeOfferAr,
} from "../../utils/landingPageConstants";

const PromotionLanding = () => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    const getPromoProducts = async () => {
      try {
        const response = await API.get(
          `/get-product-sorting?filter=Limited Time Offers`
        );
        setProducts(response?.data);
      } catch (error) {
        console.error("error : ", error);
      }
    };
    getPromoProducts();
  }, []);

  const lang = useSelector((state) => state.globalReducer.activeLanguage);
  const data = lang === "en" ? limitedTimeOffer : limitedTimeOfferAr;

  const productEnquiry = (product) => {
    if (product?.route) {
      const price =
        product?.discounted_price || 0 > product?.default_price || 0
          ? product?.discounted_price
          : product?.default_price;
      if (typeof window !== "undefined") {
        if (window.dataLayer != null) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "view_item",
            ecommerce: {
              currency: "AED",
              value: parseFloat(price).toFixed(2),
              items: [
                {
                  id: product?._id,
                  name: product.name,
                  sku: product?.product_code,
                  price: parseFloat(price).toFixed(2),
                  brand: "Pigeon Arabia",
                  category: product?.category_data?.name,
                },
              ],
            },
          });
        }
      }
      window.open("product/" + product.route, "_self");
    }
  };

  return (
    <div
      className="promotionLanding d-flex flex-column justify-content-center mt-sm-5 mt-md-0"
      style={{ fontFamily: "Oscine" }}
    >
      <div className="d-none d-md-flex flex-column justify-content-center image-fade-bottom">
        <img
          src={lang === "en" ? bannerLarge : bannerLargeAr}
          alt="Shop the Best for Baby & Mom!"
          className=""
        />
      </div>
      <div className="d-flex d-md-none flex-column justify-content-center image-fade-bottom">
        <img
          src={lang === "en" ? bannerMobile : bannerMobileAr}
          alt="Shop the Best for Baby & Mom!"
          className=""
        />
      </div>
      <div className="d-flex flex-column text-center container my-4 my-md-5 custom-color">
        <div
          className="d-flex justify-content-center"
          style={{ fontWeight: 500, fontSize: 18 }}
        >
          <p className="w-100 d-block d-md-none">{data.message}</p>
          <p className="w-75 d-none d-md-block">{data.message}</p>
        </div>
        <h3
          className="py-2 py-md-3"
          style={{ fontWeight: 900, fontSize: "clamp(1.5rem, 2vw, 3rem)" }}
        >
          {data.header}
        </h3>
        <div className="row d-flex justify-content-center">
          {products !== null ? (
            products.map((product, key) => (
              <div
                key={key}
                className="col-6 col-sm-4 col-lg-3 px-2 px-md-3 py-3 d-flex"
              >
                <div className="position-relative promo-product-card d-flex flex-column h-100 w-100">
                  {product?.discount > 0 ? (
                    <div
                      className="position-absolute"
                      style={{
                        top: 25,
                        backgroundImage:
                          "url(" +
                          (lang === "en" ? offerTag : offerTagFlipped) +
                          ")",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPositionX: "right",
                      }}
                    >
                      <span
                        className={lang === "en" ? "pl-3 pr-4" : "pr-3 pl-4"}
                        style={{ color: "white", fontWeight: 900 }}
                      >
                        {product?.discount}%
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div>
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        product?.featured_img
                      }
                      alt={
                        lang === "en" ? product?.name : product?.arabic?.name
                      }
                      className="w-100 h-100 product-image"
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-between py-3 py-md-4 px-2 flex-grow-1">
                    <div className="w-100 d-flex justify-content-center">
                      <p className="w-75 mb-1">
                        {lang === "en" ? product?.name : product?.arabic?.name}
                      </p>
                    </div>
                    <div className="d-flex flex-column ">
                      <p className="text-center mb-2 d-flex flex-column flex-lg-row justify-content-center align-items-lg-end">
                        <span
                          className={`default-price-color ${
                            lang === "en" ? "mr-lg-2" : "ml-lg-2"
                          }`}
                        >
                          {data.currency}{" "}
                          {lang === "en"
                            ? product?.default_price
                            : (product?.arabic?.default_price > 0
                                ? product?.arabic?.default_price
                                : product?.default_price) || 0}
                        </span>
                        <span className="custom-color d-flex flex-lg-row justify-content-center align-items-end">
                          {data.currency}{" "}
                          <span
                            style={{
                              fontWeight: 800,
                              fontSize: 20,
                              lineHeight: 1.4,
                            }}
                            className={lang === "en" ? "pl-1" : "pr-1"}
                          >
                            {lang === "en"
                              ? product?.discounted_price.toFixed(2)
                              : (product?.arabic?.discounted_price > 0
                                  ? product?.arabic?.discounted_price.toFixed(2)
                                  : product?.discounted_price.toFixed(2)) || 0}
                          </span>
                        </span>
                      </p>
                      <div className="w-100 d-flex justify-content-center">
                        <button
                          className="w-75 py-1"
                          style={{
                            fontWeight: "bold",
                            fontSize: "clamp(1rem, 2vw, 1.2rem)",
                          }}
                          onClick={() => productEnquiry(product)}
                        >
                          {data.buy_now}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{data.loading}...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionLanding;
